import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import {
    fetchLeagueStandings,
    fetchMatchDetail,
    fetchMatchDetailStatistic,
    fetchTeamDetail,
    fetchTimeLine
} from "../../api";
import './MatchDetail.css';
import dayjs from "dayjs";
import {Box, Tab, Tabs} from "@mui/material";
import MatchDetailsStatsEvent from "./stats-event/MatchDetailsStatsEvent";
import MatchInfo from "./match-info/MatchInfo";
import Compare from "./compare/Compare";
import MatchDetailsStatistics from "./statistics/MatchDetailsStatistics";
import MatchDetailsStandings from "./standings/MatchDetailsStandings";
import StadiumIcon from '../../assets/icons/stadium.png';
import whistle from '../../assets/icons/whistle.png';
import noTeamLogo from "../../assets/icons/noTeamLogo.png";
import {AppContext} from "../../context/AppContext";
import winIcon from '../../assets/icons/compare-win.png';
import loseIcon from '../../assets/icons/compare-lose.png';
import drawIcon from '../../assets/icons/compare-draw.png';
import matchPlusIcon from '../../assets/icons/match-plus.png';
import DocumentMeta from 'react-document-meta';
import {matchDetailsMeta} from "../../SEO/SeoMetaTags";
import Lineups from "./lineups/Lineups";
import LinearComponent from "../linearComponent/LinearComponent";

function MatchDetails(props) {
    const {lang, timeZone, i18String} = useContext(AppContext);

    const params = useParams();
    const location = useLocation();
    const matchTeamsDetail = JSON.parse(localStorage.getItem(`teams-state:${params?.id}`));

    const [matchDetailTeamData, setMatchDetailTeamData] = useState(null);
    const [matchDetailData, setMatchDetailData] = useState(null);
    const [timeLineData, setTimeLineData] = useState(null);
    const [statisticData, setStatisticData] = useState(null);
    const [standingsData, setStandingsData] = useState(null);
    const lastTabKey = `lastTabFor:${params.id}`;
    const [selectedTab, setSelectedTab] = useState(Number(localStorage.getItem(lastTabKey)) || 0);
    const [toggle, setToggle] = useState(0);
    const [eventData, setEventData] = useState('Goals');
    const userToken = localStorage.getItem('user-token');

    const statsButtons = [
        {text: `${i18String('Match Info')}`, type: 'Match Info', header: 'matchInfo', toggle: 0},
        {text: `${i18String('Lineups')}`, type: 'Lineups', header: 'lineups', toggle: 1},
        {text: `${i18String('H2H')}`, type: 'H2h', header: 'compare', toggle: 2},
        {text: `${i18String('Goals')}`, type: 'Goal', header: 'goalStatsHeader', toggle: 3},
        {text: `${i18String('Corners')}`, type: 'Corner', header: 'cornerStatsHeader', toggle: 4},
        {text: `${i18String('Cards')}`, type: 'Card', header: 'cardsStatsHeader', toggle: 5},
        /*{src: `<img className='match-plus-icon' src={matchPlusIcon} alt='Match Plus'>`, type: 'match-plus', header: 'matchPlusHeader', toggle: 5},
        {text: 'Forum', type: 'Forum', header: 'forum', toggle: 6},*/
    ]

    useEffect(() => {
        fetchMatchDetail(params.id).then(data => {
            setMatchDetailData(data.data.data);
        })

        fetchTimeLine(params.id).then(data => {
            setTimeLineData(data.data.data);
        })

        fetchMatchDetailStatistic(params.id).then(data => {
            setStatisticData(data.data.data[0]?.groups);
        })

        fetchLeagueStandings(matchTeamsDetail?.seasonId).then(data => {
            setStandingsData(data.data.data.groups)
        })

        const interval = setInterval(() => {
            fetchMatchDetail(params.id).then(data => {
                setMatchDetailData(data.data.data);
            })
        }, 20000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (selectedTab) {
            return;
        }

        if (matchDetailData === null) {
            return;
        }

        if (!localStorage.getItem(lastTabKey)) {
            if (matchDetailData?.status === 'Fixture') {
                setSelectedTab(2);
                localStorage.setItem(lastTabKey, Number(2))
            } else {
                setSelectedTab(0);
                localStorage.setItem(lastTabKey, Number(0))
            }
        }

    }, [matchDetailData])

    useEffect(() => {
        fetchTeamDetail(params.id, eventData).then(data => {
            const matchDetail = data.data.data;
            setMatchDetailTeamData(matchDetail);
        });
    }, [eventData]);

    const match = [];
    if (matchDetailTeamData !== null) {
        matchDetailTeamData.forEach((data, index) => {
            const {name} = data;
            const stats = [];
            data.stats.forEach(stat => {
                stats.push({
                    ...stat,
                });
            });
            match.push({
                title: {
                    name,
                },
                data: stats,
            });
        });
    }

    const startTime = (value) => {
        return dayjs(value).tz(timeZone).format("HH:mm");
    }

    const matchDateTime = (value) => {
        return dayjs(value).tz(timeZone).format("DD.MM.YYYY HH:mm")
    }

    const clickTab = (event) => {
        setEventData(event.type)
        /*setToggle(event.toggle);*/
        setSelectedTab(event.toggle);
        localStorage.setItem(lastTabKey, event.toggle);
    }

    const onImageError = (e) => {
        e.target.src = noTeamLogo;
    }

    const ff = (tabs) => {
        if (tabs.src) {
            return <img style={{width: '100%', height: '30px'}} src={matchPlusIcon} alt="Match Plus"/>
        } else {
            return tabs.text
        }
    }

    return (
        <DocumentMeta {...matchDetailsMeta(matchDetailData)[lang]}>
            <div className='match-details-section'>
                <div className={'const-width-content match-details-general-section'}>
                    <div className='match-detail-section'>
                        <div className='match-details-general-column-container'>
                            <div className={'stats-tabs-and-teams-section'}>
                                <div style={{padding: '5px'}}>
                                    <LinearComponent />
                                </div>

                                {/*MATCH LEAGUE, ROUND AND TIME SECTION*/}
                                {
                                    matchDetailData &&
                                    <div className='match-detail-league-round-and-time-section'>
                                        <a
                                            target={"_blank"}
                                            href={`/league/${matchDetailData.area.toLowerCase()}-${matchDetailData.league.toLowerCase().replace(/\s+/g, '-')}/${matchDetailData.leagueId}/${matchDetailData.seasonId}`}
                                            className='match-detail-area-info-container'>
                                            <img className="match-detail-area-logo"
                                                 src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${matchDetailData.areaId}.png`}
                                                 alt=""/>
                                            <a className='match-detail-area-text'> {matchDetailData.area}</a>
                                            <a className='match-detail-league-text'> - {matchDetailData.league}</a>

                                        </a>

                                        <div className='date-time-container'>
                                    <span
                                        className='match-detail-date-time'>{matchDateTime(matchDetailData.dateTimeUtc)}</span>
                                        </div>
                                    </div>
                                }

                                {/*MATCH TEAMS SECTION*/}
                                <div className={'web-teams-container'}>
                                    <div className={'match-details-team'}>
                                        {
                                            matchTeamsDetail &&
                                            <div className='match-detail-teams-general-section'>
                                                <a target={"_blank"} href={`/team-detail/${matchTeamsDetail.homeId}`}
                                                   className='match-details-team-container'>
                                                    <img className="match-detail-teams-logo"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.homeId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={matchTeamsDetail.home}/>
                                                    <a target={"_blank"}
                                                       href={`/team-detail/${matchTeamsDetail.homeId}`}
                                                       className='match-detail-team-name match-detail-team-name-for-home'>
                                                        {matchTeamsDetail.home}
                                                    </a>
                                                </a>
                                                <div className='match-detail-coach-container-for-home'>
                                                    {
                                                        matchDetailData &&
                                                        <span
                                                            className='match-detail-coach-text'>{matchDetailData.hcoach}</span>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {/*<div className='match-detail-last-5-match-container'>
                                            <span className='match-detail-last-5-match-text'>Son 5 Maç : </span>
                                            <div className='match-detail-last-5-match-result-container'>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={drawIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={loseIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                            </div>
                                        </div>*/}
                                    </div>
                                    {
                                        matchDetailData &&
                                        <div className='match-detail-match-info-container'>
                                            {
                                                matchDetailData.status === 'Fixture' &&
                                                <div className='match-start-time-container'>
                                                <span
                                                    className='match-detail-start-time'>{startTime(matchTeamsDetail?.time)}</span>
                                                    <span className='match-versus-V'>V</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Played' &&
                                                <div className='match-detail-played-match-container'>
                                                    <span className='match-detail-ms'>MS</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Playing' && matchDetailData.st !== 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <div className='match-detail-match-time'>{matchDetailData.min}'
                                                    </div>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.st === 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <span className='match-detail-match-time'>HT</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className={'match-details-team'}>
                                        {
                                            matchTeamsDetail &&
                                            <div className='match-detail-teams-general-section'>
                                                <a target={"_blank"} href={`/team-detail/${matchTeamsDetail.awayId}`} className='match-details-team-container'>
                                                    <a target={"_blank"} href={`/team-detail/${matchTeamsDetail.awayId}`} className='match-detail-team-name match-detail-team-name-for-away'>
                                                        {matchTeamsDetail.away}
                                                    </a>
                                                    <img className="match-detail-teams-logo"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail.awayId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={matchTeamsDetail.away}/>
                                                </a>

                                                <div className='match-detail-coach-container-for-away'>
                                                    {
                                                        matchDetailData &&
                                                        <span
                                                            className='match-detail-coach-text'>{matchDetailData.acoach}</span>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {/*<div className='match-detail-last-5-match-container-away'>
                                            <span className='match-detail-last-5-match-text'>Son 5 Maç : </span>
                                            <div className='match-detail-last-5-match-result-container'>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={loseIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={winIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={loseIcon} alt="Win"/>
                                                <img className='last-5-match-result' src={drawIcon} alt="Win"/>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>

                                {/*FOR MOBILE*/}
                                <div className={'mobile-teams-container'}>
                                    <div className={'match-details-team'}>
                                        <img
                                            onClick={() => {
                                                window.open(`/team-detail/${matchTeamsDetail.homeId}`)
                                            }}
                                            className="match-detail-teams-logo"
                                            src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.homeId}.png`}
                                            onError={(e) => onImageError(e)}
                                            alt={matchTeamsDetail.home}/>
                                        <a
                                            target={"_blank"} href={`/team-detail/${matchTeamsDetail.homeId}`}
                                            className={'match-detail-team-name'}>{matchTeamsDetail?.home}</a>
                                        <div className='match-detail-coach-container-for-home'>
                                            {
                                                matchDetailData &&
                                                <span
                                                    className='match-detail-coach-text'>{matchDetailData.hcoach}</span>
                                            }
                                        </div>
                                    </div>
                                    {
                                        matchDetailData &&
                                        <div className='match-detail-match-info-container'>
                                            {
                                                matchDetailData.status === 'Fixture' &&
                                                <div className='match-start-time-container'>
                                                <span
                                                    className='match-detail-start-time'>{startTime(matchTeamsDetail?.time)}</span>
                                                    <span className='match-versus-V'>V</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Played' &&
                                                <div className='match-detail-played-match-container'>
                                                    <span className='match-detail-ms'>MS</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.status === 'Playing' && matchDetailData.st !== 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <span
                                                        className='match-detail-match-time'>{matchDetailData.min}'</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                            {
                                                matchDetailData.st === 'HT' &&
                                                <div className='match-detail-score-and-time'>
                                                    <span className='match-detail-match-time'>HT</span>
                                                    <span
                                                        className='match-detail-score'>{matchDetailData.hscore} - {matchDetailData.ascore}</span>
                                                    <span
                                                        className='match-detail-first-period-score'>İY: {matchDetailData.hp1} - {matchDetailData.ap1}</span>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className={'match-details-team'}>
                                        {/*<div
                                    onClick={() => {
                                        window.open(`/team-detail/${matchTeamsDetail.awayId}`)
                                    }}
                                    className='match-details-team-container'>

                                </div>*/}
                                        <img
                                            onClick={() => {
                                                window.open(`/team-detail/${matchTeamsDetail.awayId}`)
                                            }}
                                            className="match-detail-teams-logo"
                                            src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/teams/${matchTeamsDetail?.awayId}.png`}
                                            onError={(e) => onImageError(e)}
                                            alt={matchTeamsDetail?.away}/>
                                        <a
                                            target={"_blank"} href={`/team-detail/${matchTeamsDetail.awayId}`}
                                            className={'match-detail-team-name'}>{matchTeamsDetail?.away}</a>
                                        <div className='match-detail-coach-container-for-away'>
                                            {
                                                matchDetailData &&
                                                <span
                                                    className='match-detail-coach-text'>{matchDetailData.acoach}</span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/*MATCH VENUE AND REFEREE SECTION*/}
                                {
                                    matchTeamsDetail?.venue || matchTeamsDetail?.referee ||
                                    <div className='match-detail-venue-and-referee-container'>
                                        <div className='match-detail-venue-container'>
                                            <img className='match-detail-stadium-icon' src={StadiumIcon} alt="Stadium"/>
                                            <span
                                                className='match-detail-venue-city'>{matchDetailData?.venue?.venueCity} </span>
                                            <span
                                                className='match-detail-venue'> - {matchDetailData?.venue?.venue}</span>
                                        </div>

                                        <div className='referee-info-section'>
                                            {
                                                !!matchDetailData?.referee &&
                                                <div className='match-detail-referee-container'>
                                                    <img className='match-detail-whistle-icon' src={whistle}
                                                         alt="Referee"/>
                                                    <span
                                                        className='referee-name'>{matchDetailData?.referee?.referee}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='match-detail-empty-section'></div>
                        </div>

                        <div className='match-details-content-section'>
                            <div className='match-details-general-column-container'>
                                <div style={{flex: 2}}>
                                    {/*MATCH TABS SECTION*/}
                                    <div className='match-details-tabs-section'>
                                        <Tabs variant={"scrollable"}
                                              TabIndicatorProps={{style: {display: "none"}}} value={selectedTab}>
                                            {
                                                statsButtons.map(tabs => {
                                                    return (
                                                        <Tab className={'best-teams-tab'} label={ff(tabs)}
                                                             onClick={() => clickTab(tabs)}></Tab>
                                                    )
                                                })
                                            }
                                        </Tabs>
                                    </div>
                                    {
                                        selectedTab === 0 &&
                                        <MatchInfo matchTeamsDetail={matchTeamsDetail}
                                                   lang={lang}
                                                   timeLineData={timeLineData}
                                        >

                                        </MatchInfo>
                                    }
                                    {
                                        selectedTab === 1 &&
                                        <Lineups matchTeamsDetail={matchTeamsDetail}
                                                 lang={lang}
                                                 matchId={params.id}
                                                 i18String={i18String}
                                        >

                                        </Lineups>
                                    }
                                    {
                                        selectedTab === 2 &&
                                        <Compare matchTeamsDetail={matchTeamsDetail}
                                                 lang={lang}
                                                 matchId={params.id}
                                                 i18String={i18String}
                                        ></Compare>
                                    }
                                    {
                                        selectedTab !== 0 && selectedTab !== 1 && selectedTab !== 2 &&
                                        <MatchDetailsStatsEvent
                                            matchDetailTeamData={matchDetailTeamData}
                                            i18String={i18String}
                                        >
                                        </MatchDetailsStatsEvent>
                                    }
                                </div>

                                <div className='statistics-and-standings-section'>
                                    <MatchDetailsStatistics
                                        lang={lang}
                                        statisticData={statisticData}
                                        i18String={i18String}
                                    >
                                    </MatchDetailsStatistics>
                                    <MatchDetailsStandings
                                        statisticData={statisticData}
                                        matchDetailData={matchDetailData}
                                        lang={lang}
                                        matchTeamsDetail={matchTeamsDetail}
                                        standingsData={standingsData}
                                    >
                                    </MatchDetailsStandings>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentMeta>
    );
}

export default MatchDetails;
