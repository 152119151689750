import React, {useContext} from 'react';
import favIcon from "../../assets/icons/fav-on.svg";
import favOff from "../../assets/icons/fav-off.svg";
import dayjs from "dayjs";
import {AppContext} from "../../context/AppContext";

function BetModeMatches(props) {

    const {addAndRemoveFavBetModeMatches, isFavBetMode, timeZone, i18String} = useContext(AppContext);

    const {league} = props;

    const startTime = (value) => {
        return dayjs(value).tz(timeZone).format("HH:mm");
    }

    return (
        <div>
            {
                league.matches.map((match) => {
                    return (
                        <div className={'live-scores-general-table-section'} key={match.id}>
                            {/*WEB*/}
                            <div className="bet-mode-matches-container match-team-names">
                                <table className="fixtures-table-section">
                                    <tbody>
                                    <tr className="live-scores-fixture-table-row">
                                        <td className="match-info-td">
                                            <div className="bet-mode-match-info-container">
                                                <div>
                                                    {match.status !== 'Played' &&
                                                        <div>
                                                            {
                                                                isFavBetMode(match.id) ?
                                                                    <img
                                                                        onClick={() => addAndRemoveFavBetModeMatches(match)}
                                                                        className="fav-icon" src={favIcon} alt="fav"/> :
                                                                    <img
                                                                        onClick={() => addAndRemoveFavBetModeMatches(match)}
                                                                        className="fav-icon" src={favOff} alt="fav"/>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Postponed' && <span className='postponed-status'>{i18String('Post.')}</span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match?.st === 'HT' && <span className="live-match-min">HT</span>}
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>
                                                    }
                                                    {match.hyellow > 0 &&
                                                        <div className="match-card yellow-card">{match.hyellow}</div>
                                                    }
                                                </div>

                                                <a target={"_blank"} href={`/team-detail/${match.homeId}`} className="match-team-name">
                                                    {match.home}
                                                </a>
                                                <div
                                                    className="score-container-bet-mode"
                                                    onClick={() => {
                                                        localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                            {
                                                                home: match.home,
                                                                homeId: match.homeId,
                                                                away: match.away,
                                                                awayId: match.awayId,
                                                                time: match.dateTimeUtc,
                                                                seasonId: match.seasonId
                                                            }
                                                        ))
                                                        window.open(`/match-detail/${match.id}`)
                                                    }}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            style={{color: 'var(--H3Red01)', fontWeight: '600'}}>{match.hscore} -</span>  <span
                                                            style={{color: 'var(--H3Red01)', fontWeight: '600'}}>{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{color: 'black', fontWeight: '600'}}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' &&
                                                        <span style={{color: 'black', fontSize: '.8rem'}}>-</span>}
                                                    {match.status === 'Postponed' &&
                                                        <span style={{color: 'black', fontSize: '.8rem'}}>-</span>}
                                                </div>
                                                <a target={"_blank"} href={`/team-detail/${match.awayId}`} className="match-team-name away-team-name">
                                                    {match.away}
                                                </a>

                                                <div className="card-container">
                                                    {match.ayellow > 0 &&
                                                        <div className="match-card yellow-card">{match.ayellow}</div>
                                                    }
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    {/*<td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>Corner</span>
                                                    </td>*/}
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hyellow} - {match.ayellow}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    {/*<td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>Shots</span>
                                                    </td>*/}
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hcorner} - {match.acorner}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    {/*<td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>S.Target</span>
                                                    </td>*/}
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hshot} - {match.ashot}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    {/*<td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>Attack</span>
                                                    </td>*/}
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hsot} - {match.asot}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    {/*<td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>Dan.Attack</span>
                                                    </td>*/}
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hfoul} - {match.afoul}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    {/*<td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>Poss.</span>
                                                    </td>*/}
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hposs} - {match.aposs}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*MOBILE*/}
                            <div className="fixtures-section-mobile match-team-names">
                                <table className="fixtures-table-section">
                                    <tbody>
                                    <tr className="fixture-table-row">
                                        <td className="match-info-td">
                                            <div className="bet-mode-match-info-container">
                                                <div>
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Postponed' && <span className='postponed-status'>{i18String('Post.')}</span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match?.st === 'HT' && <span className="live-match-min">HT</span>}
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <span className="match-team-name">{match.home}</span>
                                                <div className="score-container-bet-mode"
                                                     onClick={() => {
                                                         localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                             {
                                                                 home: match.home,
                                                                 homeId: match.homeId,
                                                                 away: match.away,
                                                                 awayId: match.awayId,
                                                                 time: match.dateTimeUtc,
                                                                 seasonId: match.seasonId
                                                             }
                                                         ))
                                                         window.open(`/match-detail/${match.id}`);
                                                     }}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            style={{color: 'var(--H3Red01)', fontWeight: '600'}}>{match.hscore} -</span>  <span
                                                            style={{color: 'var(--H3Red01)', fontWeight: '600'}}>{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{color: 'black', fontWeight: '600'}}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' &&
                                                        <span className='bet-mode-start-time-for-mobile'>
                                                            {match.dateTimeUtc ? startTime(match.dateTimeUtc) : '-'}
                                                        </span>
                                                    }
                                                    {match.status === 'Postponed' &&
                                                        <span className='bet-mode-start-time-for-mobile'>
                                                            {match.dateTimeUtc ? startTime(match.dateTimeUtc) : '-'}
                                                        </span>
                                                    }
                                                </div>
                                                <span className="match-team-name away-team-name">{match.away}</span>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>

                                                <div>
                                                    {match.status !== 'Played' &&
                                                        <div>
                                                            {
                                                                isFavBetMode(match.id) ?
                                                                    <img
                                                                        onClick={() => addAndRemoveFavBetModeMatches(match)}
                                                                        className="fav-icon" src={favIcon} alt="fav"/> :
                                                                    <img
                                                                        onClick={() => addAndRemoveFavBetModeMatches(match)}
                                                                        className="fav-icon" src={favOff} alt="fav"/>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{display: 'flex', padding: '5px', background: 'var(--div-bg01)'}}>
                                        <td className={'live-scores-stats-td-section'} style={{border: 'none'}}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    <td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>{i18String('Y. Card')}</span>
                                                    </td>
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hyellow} - {match.ayellow}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    <td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>{i18String('Corner')}</span>
                                                    </td>
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hcorner} - {match.acorner}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    <td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>{i18String('Shots')}</span>
                                                    </td>
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hshot} - {match.ashot}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    <td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>{i18String('S.Target')}</span>
                                                    </td>
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hsot} - {match.asot}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    <td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>{i18String('Foul')}</span>
                                                    </td>
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hfoul} - {match.afoul}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>

                                        <td className={'live-scores-stats-td-section'}>
                                            <div className={'live-scores-stats-container'}>
                                                <tr className={'live-scores-stats-tr-container'}>
                                                    <td className={'live-scores-stats-name-container'}>
                                                        <span className={'live-scores-stats-name'}>{i18String('Poss.')}</span>
                                                    </td>
                                                    <td className={'live-scores-stats-text-container'}>
                                                        <span className={'live-scores-stats'}>{match.hposs} - {match.aposs}</span>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default BetModeMatches;
