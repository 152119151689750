import React, {useContext, useState} from 'react';
import TextField from "@mui/material/TextField";
import sadImg from "../../assets/icons/sad.png";
import {Link} from "react-router-dom";
import cancelImg from "../../assets/icons/cancel.png";
import {AppContext} from "../../context/AppContext";

function CancelSubscription(props) {
    const {i18String} = useContext(AppContext);

    const userToken = localStorage.getItem('user-token');
    const [loading, setLoading] = useState(true);
    const [visibleForm, setVisibleForm] = useState(true);
    const [cancelData, setCancelData] = useState(null);

    const requestCancelSubscription = {
        method: 'POST',
        headers: {'Authorization': `Bearer ${userToken}`},
    };

    const handleCancelClick = async () => {
        await fetch(`https://santra24.6stats.com/api/v1/payment/cancel-subscription`, requestCancelSubscription)
            .then(async (response) => {
                const responseObject = await response.json();
                console.log('Cancel Subs Data: ', responseObject);
                if (responseObject) {
                    setCancelData(responseObject);
                    localStorage.removeItem('user');
                    setLoading(false);

                    const detailPayload = {
                        method: 'GET',
                        headers: {'Authorization': `Bearer ${userToken}`},
                    }
                    const userDetail = await fetch('https://santra24.6stats.com/api/user/me', detailPayload);
                    const user = await userDetail.json();
                    console.log('success user me ', user)
                    if (user.success === true) {
                        localStorage.setItem('user', JSON.stringify(user.data));
                        setVisibleForm(false);
                    }
                }
            })
            .catch((err) => err);
    }

    return (
        <div className='cancel-subscription-section'>
            <div className='cancel-subscription-container'>
                {
                    visibleForm ?
                        <div>
                            <div className='membership-container'>
                                <h2 className='your-membership-title'>{i18String('Your Membership')}</h2>
                                <p className='membership-text'>{i18String('Here you can manage your membership with Stats365.')}</p>
                            </div>

                            <div className='account-type-container'>
                                <h4 className='account-type-title'>{i18String('Account Type')}: Premium</h4>
                                <p className='text-style'>{i18String('I want to unsubscribe from Premium.')}</p>
                                <p className='cancel-sub-question-text'>
                                    {i18String('Are you sure you would like to Unsubscribe from Premium? Unsubscribing will immediately remove all Premium features from your account.')}
                                </p>
                            </div>

                            <div className='text-field-and-cancel-button-container'>
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    defaultValue="Please fill out a reason, it would help us improve!"
                                    sx={{
                                        borderColor: 'red !important',
                                        '& .MuiInputBase-input': {
                                            color: 'var(--H3Dark1)',
                                        },
                                    }}
                                />
                                <button onClick={() => handleCancelClick()} className='cancel-subs-button'>
                                    {i18String('Cancel Subscription')}
                                </button>
                            </div>
                        </div>
                        :
                        <div>

                            {
                                cancelData.data === true ?
                                    <div>
                                        <div className='success-message-container'>
                                            <div className='success-text-and-img-container'>
                                                <img className='sad-icon' src={sadImg} alt="Success"/>
                                                <span className='success-text'>{i18String('Subscription cancel is successful.')}</span>
                                            </div>

                                            <div className='go-home-page-button-container'>
                                                <Link className='go-home-page-button' to={'/'}>{i18String('Go Home Page')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className='success-message-container'>
                                            <div className='success-text-and-img-container'>
                                                <img className='success-check-icon' src={cancelImg} alt="Success"/>
                                                <span className='success-text'>{i18String('An error occurred while unsubscribing. Try again or contact us.')}</span>
                                            </div>

                                            <div className='go-home-page-button-container'>
                                                <Link className='go-home-page-button' to={'/'}>{i18String('Go Home Page')}</Link>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default CancelSubscription;
