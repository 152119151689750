import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchTeamFixtures} from "../../../api";
import {colorTheme} from "../../ColorCss";
import dayjs from "dayjs";
import noFlagIcon from "../../../assets/icons/noFlagIcon.png";

function TeamFixtures(props) {

    const params = useParams();

    const {side, teamDetailsData, toggleBottomTabs} = props;

    const [fixturesData, setFixturesData] = useState(null);

    useEffect(() => {
        fetchTeamFixtures(params.id).then(data => {
            setFixturesData(data.data.data);
        })
    }, [params.id]);

    if (!fixturesData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    const dateForMatch = value => {
        return dayjs(value).format('DD.MM.YYYY');
    };

    const teamId = teamDetailsData?.id;
    const filterMatchesBySide = (league) => {
        if (fixturesData) {
            return league?.matches.filter((match) => {
                if (side === 'home') {
                    return match.homeId === teamId
                } else if (side === 'away') {
                    return match.awayId === teamId
                } else if (side === 'all') {
                    return match
                }
            })
        }
    }
    filterMatchesBySide();

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div>
                {/*MATCHES BY LEAGUE*/}
                {
                    toggleBottomTabs === 0 &&
                    <div>
                        {
                            fixturesData &&
                            fixturesData.matchesByLeague.map(league => {
                                return (
                                    <div>
                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            <tr className='team-detail-results-table-header'>
                                                <div className='results-date-container'>
                                                    <img className="results-and-fixtures-country-flag"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={league.area}/>
                                                    <a  target={"_blank"}
                                                        href={`/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${league.leagueId}/${league.seasonId}`}
                                                        className='team-detail-results-area'>{league.area} {league.league}</a>
                                                </div>
                                                {/*<div className='team-detail-tables-header'>
                                                        IY
                                                    </div>*/}
                                                <div></div>
                                                <div></div>
                                                <div></div>

                                                <div></div>

                                                {/*<div className='team-detail-tables-header'>2.5AÜ</div>
                                                    <div className='team-detail-tables-header'>KG</div>*/}

                                                <div></div>
                                            </tr>
                                        </table>

                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            {
                                                filterMatchesBySide(league).map((match, index) => {
                                                    return (
                                                        <tr
                                                            style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}
                                                            className='team-detail-results-table-row-container'>
                                                            <div className='results-match-date'>{dateForMatch(match.date)}</div>

                                                            {/*<div className="card-container">
                                                                    {match.hred > 0 &&
                                                                        <div className="match-card red-card">{match.hred}</div>}
                                                                </div>*/}

                                                            <div className='team-detail-results-home-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.homeId}`}
                                                                        style={{color: match.homeId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-home-team'>
                                                                        {match.home}
                                                                    </a>
                                                            </div>

                                                            <div
                                                                onClick={() => {
                                                                    localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                                        {
                                                                            home: match.home,
                                                                            homeId: match.homeId,
                                                                            away: match.away,
                                                                            awayId: match.awayId,
                                                                            time: match.dateTimeUtc,
                                                                            seasonId: match.seasonId
                                                                        }
                                                                    ))
                                                                    window.open(`/match-detail/${match.id}`)
                                                                }}
                                                            >
                                                                <a className='team-detail-results-score'>{match.hscore} - {match.ascore}</a>
                                                            </div>

                                                            <div className='team-detail-results-away-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.awayId}`}
                                                                        style={{color: match.awayId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-away-team'>
                                                                        {match.away}
                                                                    </a>
                                                            </div>

                                                            {/*<div className="card-container">
                                                                    {match.ared > 0 &&
                                                                        <div className="match-card red-card">{match.ared}</div>}
                                                                </div>*/}

                                                            {/*<div className='team-detail-results-ht-score'>
                                                                    {match.hp1} - {match.ap1}
                                                                </div>*/}

                                                            <div></div>

                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {/*MATCHES BY TIME*/}
                {
                    toggleBottomTabs === 1 &&
                    <div>
                        {
                            fixturesData &&
                            fixturesData.matchesByTime.map(league => {
                                return (
                                    <div>
                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            <tr className='team-detail-results-table-header'>
                                                <div className='results-date-container'>
                                                    <img className="results-and-fixtures-country-flag"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                         onError={(e) => onImageError(e)}
                                                         alt={league.area}/>
                                                    <a  target={"_blank"}
                                                        href={`/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${league.leagueId}/${league.seasonId}`}
                                                        className='team-detail-results-area'>{league.area} {league.league}</a>
                                                </div>
                                                {/*<div className='team-detail-tables-header'>
                                                        IY
                                                    </div>*/}

                                                <div></div>
                                                <div></div>
                                                <div></div>

                                                <div></div>

                                                {/*<div className='team-detail-tables-header'>2.5AÜ</div>
                                                    <div className='team-detail-tables-header'>KG</div>*/}

                                                <div></div>
                                            </tr>
                                        </table>

                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            {
                                                filterMatchesBySide(league).map((match, index) => {
                                                    return (
                                                        <tr
                                                            style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}
                                                            className='team-detail-results-table-row-container'>
                                                            <div className='results-match-date'>{dateForMatch(match.date)}</div>

                                                            {/*<div className="card-container">
                                                                    {match.hred > 0 &&
                                                                        <div className="match-card red-card">{match.hred}</div>}
                                                                </div>*/}

                                                            <div className='team-detail-results-home-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.homeId}`}
                                                                        style={{color: match.homeId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-home-team'>
                                                                        {match.home}
                                                                    </a>
                                                            </div>

                                                            <div
                                                                onClick={() => {
                                                                    localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                                        {
                                                                            home: match.home,
                                                                            homeId: match.homeId,
                                                                            away: match.away,
                                                                            awayId: match.awayId,
                                                                            time: match.dateTimeUtc,
                                                                            seasonId: match.seasonId
                                                                        }
                                                                    ))
                                                                    window.open(`/match-detail/${match.id}`)
                                                                }}
                                                            >
                                                                <a className='team-detail-results-score'>{match.hscore} - {match.ascore}</a>
                                                            </div>

                                                            <div className='team-detail-results-away-container'>
                                                                    <a  target={"_blank"} href={`/team-detail/${match?.awayId}`}
                                                                        style={{color: match.awayId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                        className='team-detail-results-team-name team-detail-results-away-team'>
                                                                        {match.away}
                                                                    </a>
                                                            </div>

                                                            {/*<div className="card-container">
                                                                    {match.ared > 0 &&
                                                                        <div className="match-card red-card">{match.ared}</div>}
                                                                </div>*/}

                                                            {/*<div className='team-detail-results-ht-score'>
                                                                    {match.hp1} - {match.ap1}
                                                                </div>*/}
                                                            <div></div>

                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                }

            {/*MATCHES BY LEAGUE*/}
            {/*{
                toggleBottomTabs === 0 &&
                <div>
                    <div>
                        {
                            fixturesData &&
                            fixturesData.matchesByLeague.map(league => {
                                return (
                                    <div>
                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            <tr className='results-table-header'>
                                                <div className='results-date-container'>
                                                    <img className="country-flag"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                         alt="country"/>
                                                    <span style={{marginLeft: '5px'}}>{league.area}</span>
                                                </div>
                                            </tr>
                                        </table>
                                        <div>
                                            <table style={{width: '100%', borderSpacing: 0}}>
                                                {
                                                    league.matches.map(match => {
                                                        return (
                                                            <tr className='team-detail-fixtures-table-row-container'>
                                                                <div className='team-detail-match-date'>{match.date}</div>

                                                                <div className='results-home-container'>
                                                                    <span className='results-team-name'>{match.home}</span>
                                                                </div>

                                                                <div className='fixtures-versus-container'>
                                                                    <span className='fixtures-versus-text'>V</span>
                                                                </div>

                                                                <div className='results-away-container'>
                                                                    <span className='results-team-name'>{match.away}</span>
                                                                </div>

                                                                <div></div>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }*/}

            {/*MATCHES BY TIME*/}
            {/*{
                toggleBottomTabs === 1 &&
                <div>
                    <div>
                        {
                            fixturesData &&
                            fixturesData.matchesByTime.map(league => {
                                return (
                                    <div>
                                        <table style={{width: '100%', borderSpacing: 0}}>
                                            <tr className='results-table-header'>
                                                <div className='results-date-container'>
                                                    <img className="country-flag"
                                                         src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                         alt="country"/>
                                                    <span style={{marginLeft: '5px'}}>{league.area}</span>
                                                </div>
                                            </tr>
                                        </table>
                                        <div>
                                            <table style={{width: '100%', borderSpacing: 0}}>
                                                {
                                                    league.matches.map(match => {
                                                        return (
                                                            <tr className='team-detail-fixtures-table-row-container'>
                                                                <div className='team-detail-match-date'>{match.date}</div>

                                                                <div className='results-home-container'>
                                                                    <span className='results-team-name'>{match.home}</span>
                                                                </div>

                                                                <div className='fixtures-versus-container'>
                                                                    <span className='fixtures-versus-text'>V</span>
                                                                </div>

                                                                <div className='results-away-container'>
                                                                    <span className='results-team-name'>{match.away}</span>
                                                                </div>

                                                                <div></div>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }*/}
        </div>
    );
}

export default TeamFixtures;
