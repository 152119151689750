import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchTeamResults} from "../../../api";
import './Results.css';
import {colorTheme} from "../../ColorCss";
import dayjs from "dayjs";
import winIcon from '../../../assets/icons/compare-win.png'
import loseIcon from '../../../assets/icons/compare-lose.png'
import drawIcon from '../../../assets/icons/compare-draw.png'
import compareIcon from '../../../assets/icons/compare.gif'
import noFlagIcon from "../../../assets/icons/noFlagIcon.png";

function TeamResults(props) {

    const params = useParams();

    const {side, teamDetailsData, toggleBottomTabs} = props;

    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        fetchTeamResults(params.id).then(data => {
            setResultsData(data.data.data);
        })

    }, [params.id, side]);


    const teamId = teamDetailsData?.id;
    const filterMatchesBySide = (league) => {
        if (resultsData) {
            return league?.matches.filter((match) => {
                if (side === 'home') {
                    return match.homeId === teamId
                } else if (side === 'away') {
                    return match.awayId === teamId
                } else if (side === 'all') {
                    return match
                }
            })
        }
    }
    filterMatchesBySide();

    const dateForMatch = value => {
        return dayjs(value).format('DD.MM.YYYY');
    };

    if (!resultsData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    const calculate25Over = (hscore, ascore) => {
        if (hscore + ascore >= 3) {
            return 'Ü'
        } else {
            return 'A'
        }
    }

    const calculate25OverStyle = (hscore, ascore) => {
        if (hscore + ascore >= 3) {
            return {
                fontWeight: 'bold',
                color: '#5bbf5c'
            }
        } else {
            return {
                fontWeight: 'bold',
                color: '#d44c4c'
            }
        }
    }

    const calculateKG = (hscore, ascore) => {
        if (hscore > 0 && ascore > 0) {
            return 'KG'
        } else {
            return '-'
        }
    }

    const calculateMatchResult = (teamId, hscore, ascore) => {
        if (teamId === teamDetailsData?.id) {
            if (hscore > ascore) {
                return winIcon
            } else if (ascore > hscore) {
                return loseIcon
            } else {
                return drawIcon
            }
        } else {
            if (ascore > hscore) {
                return winIcon
            } else if (hscore > ascore) {
                return loseIcon
            } else {
                return drawIcon
            }
        }
    }

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    return (
        <div className='team-result-section'>
            <div>
                {/*MATCHES BY LEAGUE*/}
                {
                    toggleBottomTabs === 0 &&
                    <div>
                        <div>
                            {
                                resultsData &&
                                resultsData.matchesByLeague.map(league => {
                                    return (
                                        <div>
                                            <table style={{width: '100%', borderSpacing: 0}}>
                                                <tr className='team-detail-results-table-header'>
                                                    <div className='results-date-container'>
                                                        <img className="results-and-fixtures-country-flag"
                                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                             onError={(e) => onImageError(e)}
                                                             alt={league.area}/>
                                                        <a target={"_blank"}
                                                           href={`/league/${league.area.toLowerCase()}-${league.league.toLowerCase().replace(/\s+/g, '-')}/${league.leagueId}/${league.seasonId}`}
                                                           className='team-detail-results-area'>{league.area} {league.league}</a>
                                                    </div>
                                                    <div className='team-detail-tables-header'>
                                                        IY
                                                    </div>

                                                    <div></div>

                                                    <div
                                                        className='team-detail-tables-header different-dimension'>2.5AÜ
                                                    </div>
                                                    <div className='team-detail-tables-header different-dimension'>KG
                                                    </div>

                                                    <div></div>
                                                </tr>
                                            </table>

                                            <table style={{width: '100%', borderSpacing: 0}}>
                                                {
                                                    filterMatchesBySide(league).map((match, index) => {
                                                        return (
                                                            <tr
                                                                style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}
                                                                className='team-detail-results-table-row-container'>
                                                                <div
                                                                    className='results-match-date'>{dateForMatch(match.date)}</div>

                                                                {/*<div className="card-container">
                                                                    {match.hred > 0 &&
                                                                        <div className="match-card red-card">{match.hred}</div>}
                                                                </div>*/}

                                                                <div className='team-detail-results-home-container'>
                                                                    <a target={"_blank"}
                                                                       href={`/team-detail/${match?.homeId}`}
                                                                       style={{color: match.homeId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                       className='team-detail-results-team-name team-detail-results-home-team'>
                                                                        {match.home}
                                                                    </a>
                                                                </div>

                                                                <div
                                                                    onClick={() => {
                                                                        localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                                            {
                                                                                home: match.home,
                                                                                homeId: match.homeId,
                                                                                away: match.away,
                                                                                awayId: match.awayId,
                                                                                time: match.dateTimeUtc,
                                                                                seasonId: match.seasonId
                                                                            }
                                                                        ))
                                                                        window.open(`/match-detail/${match.id}`)
                                                                    }}
                                                                >
                                                                    <a className='team-detail-results-score'>{match.hscore} - {match.ascore}</a>
                                                                </div>

                                                                <div className='team-detail-results-away-container'>
                                                                    <a target={"_blank"}
                                                                       href={`/team-detail/${match?.awayId}`}
                                                                       style={{color: match.awayId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                       className='team-detail-results-team-name team-detail-results-away-team'>
                                                                        {match.away}
                                                                    </a>
                                                                </div>

                                                                {/*<div className="card-container">
                                                                    {match.ared > 0 &&
                                                                        <div className="match-card red-card">{match.ared}</div>}
                                                                </div>*/}

                                                                <div className='team-detail-results-ht-score'>
                                                                    {match.hp1} - {match.ap1}
                                                                </div>

                                                                <div></div>

                                                                <div>
                                                                    <span
                                                                        style={calculate25OverStyle(match.hscore, match.ascore)}>
                                                                        {calculate25Over(match.hscore, match.ascore)}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        style={{color: '#444444'}}>{calculateKG(match.hscore, match.ascore)}</span>
                                                                </div>
                                                                <div className='match-reuslts-icons-container'>
                                                                    <img className='match-results-icons'
                                                                         src={calculateMatchResult(match.homeId, match.hscore, match.ascore)}
                                                                         alt=""/>
                                                                </div>
                                                                <div>
                                                                    <img src={compareIcon} alt="Compare"/>
                                                                </div>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {/*MATCHES BY TIME*/}
                {
                    toggleBottomTabs === 1 &&
                    <div>
                        <div>
                            {
                                resultsData &&
                                resultsData.matchesByTime.map(league => {
                                    return (
                                        <div>
                                            <table style={{width: '100%', borderSpacing: 0}}>
                                                <tr className='team-detail-results-table-header'>
                                                    <div className='results-date-container'>
                                                        <img className="results-and-fixtures-country-flag"
                                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.areaId}.png`}
                                                             onError={(e) => onImageError(e)}
                                                             alt="country"/>
                                                        <a className='team-detail-results-area'>{league.area} {league.league}</a>
                                                    </div>
                                                    <div className='team-detail-tables-header'>
                                                        IY
                                                    </div>

                                                    <div></div>

                                                    <div
                                                        className='team-detail-tables-header different-dimension'>2.5AÜ
                                                    </div>
                                                    <div className='team-detail-tables-header different-dimension'>KG
                                                    </div>

                                                    <div></div>
                                                </tr>
                                            </table>

                                            <table style={{width: '100%', borderSpacing: 0}}>
                                                {
                                                    filterMatchesBySide(league).map((match, index) => {
                                                        return (
                                                            <tr
                                                                style={{background: index % 2 === 0 ? '#ffffff' : '#f1f1f1'}}
                                                                className='team-detail-results-table-row-container'>
                                                                <div
                                                                    className='results-match-date'>{dateForMatch(match.date)}</div>

                                                                {/*<div className="card-container">
                                                                    {match.hred > 0 &&
                                                                        <div className="match-card red-card">{match.hred}</div>}
                                                                </div>*/}

                                                                <div className='team-detail-results-home-container'>
                                                                    <a target={"_blank"}
                                                                       href={`/team-detail/${match?.homeId}`}
                                                                       style={{color: match.homeId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                       className='team-detail-results-team-name team-detail-results-home-team'>
                                                                        {match.home}
                                                                    </a>
                                                                </div>

                                                                <div
                                                                    onClick={() => {
                                                                        localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                                            {
                                                                                home: match.home,
                                                                                homeId: match.homeId,
                                                                                away: match.away,
                                                                                awayId: match.awayId,
                                                                                time: match.dateTimeUtc,
                                                                                seasonId: match.seasonId
                                                                            }
                                                                        ))
                                                                        window.open(`/match-detail/${match.id}`)
                                                                    }}
                                                                >
                                                                    <a className='team-detail-results-score'>{match.hscore} - {match.ascore}</a>
                                                                </div>

                                                                <div className='team-detail-results-away-container'>
                                                                    <a target={"_blank"}
                                                                       href={`/team-detail/${match?.awayId}`}
                                                                       style={{color: match.awayId === params.id ? colorTheme.selectedTeam : colorTheme.normalTeam}}
                                                                       className='team-detail-results-team-name team-detail-results-away-team'>
                                                                        {match.away}
                                                                    </a>
                                                                </div>

                                                                {/*<div className="card-container">
                                                                    {match.ared > 0 &&
                                                                        <div className="match-card red-card">{match.ared}</div>}
                                                                </div>*/}

                                                                <div className='team-detail-results-ht-score'>
                                                                    {match.hp1} - {match.ap1}
                                                                </div>

                                                                <div></div>

                                                                <div>
                                                                    <span
                                                                        style={calculate25OverStyle(match.hscore, match.ascore)}>
                                                                        {calculate25Over(match.hscore, match.ascore)}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        style={{color: '#444444'}}>{calculateKG(match.hscore, match.ascore)}</span>
                                                                </div>
                                                                <div className='match-reuslts-icons-container'>
                                                                    <img className='match-results-icons'
                                                                         src={calculateMatchResult(match.homeId, match.hscore, match.ascore)}
                                                                         alt=""/>
                                                                </div>
                                                                <div>
                                                                    <img src={compareIcon} alt="Compare"/>
                                                                </div>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default TeamResults;
