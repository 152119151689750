import React, {useContext, useEffect, useState} from 'react';
import StatsHeader from "./StatsHeader";
import Matches from "./Matches";
import {AppContext} from "../../context/AppContext";
import noFlagIcon from "../../assets/icons/noFlagIcon.png";
import rightIcon from "../../assets/icons/right-icon.png";

function LeagueAndMatchesTable(props) {
    const {lang, i18String} = useContext(AppContext);
    const [showId, setShowId] = useState(false);
    const {league, currentStats, matches, statsType, favMatches, selectedTypeForSelect, index, live, searchTerm} = props;

    const [previousMatches, setPreviousMatches] = useState([]);

    useEffect(() => {
        if (searchTerm !== '') {
            if (matches.length > 0) {
                // Sadece yeni maçlar geldiğinde işleme devam ediliyor
                const newMatches = matches.filter(
                    match => !previousMatches.some(prev => prev.id === match.id)
                );

                // Eğer yeni maçlar varsa, her bir maçın id'sini toggleShow'a gönderiyoruz
                if (newMatches.length > 0 && index >= 19) {
                    newMatches.forEach(match => {
                        if (match.leagueId) {
                            toggleShow(match.leagueId);
                        }
                    });

                    // Eski veriyi güncelliyoruz
                    setPreviousMatches(matches);
                }
            }
        }
    }, [matches, index, previousMatches]);

    const [statsHeader, setStatsHeader] = useState({
        goalStatsHeader: [
            {name: 'FH +0.5 Goals', text: `${i18String('FH +0.5')}`},
            {name: '+1.5 Goals', text: '+1.5'},
            {name: '+2.5 Goals', text: '+2.5'},
            {name: 'BTTS', text: `${i18String('BTTS')}`},
        ],
        cornerStatsHeader: [
            {name: '+8.5 Corners', text: '+8.5'},
            {name: '+9.5 Corners', text: '+9.5'},
            {name: '+10.5 Corners', text: '+10.5'},
            {name: '+11.5 Corners', text: '+11.5'},
        ],
        cardsStatsHeader: [
            {name: '+3.5 Cards', text: '+3.5'},
            {name: '+4.5 Cards', text: '+4.5'},
            {name: '+5.5 Cards', text: '+5.5'},
            {name: '+6.5 Cards', text: '+6.5'},
        ],
        avgStatsHeader: [
            {name: 'Goals PG', text: `${i18String('Goals')}`},
            {name: 'Corners PG', text: `${i18String('Corners')}`},
            {name: 'Cards PG', text: `${i18String('Cards')}`},
        ],
        refereeStatsHeader: [
            {name: 'Yellows PG', text: `${i18String('Yellows PG')}`},
            {name: 'Reds PG', text: `${i18String('Reds PG')}`},
            {name: 'Pens PG', text: `${i18String('Pens PG')}`},
            {name: 'Fouls PG', text: `${i18String('Fouls PG')}`},
        ]
    })

    const onImageError = (e) => {
        e.target.src = noFlagIcon;
    }

    const showClick = (id) => {
        /*setShowMore(!showMore);*/
        setShowId(id);

    }
    const showLess = (id) => {
        /*setShowMore(!showMore);*/
        if (showId === id) {
            setShowId(undefined);
        }
    }

    const toggleShow = (id) => {
        if (index >= 20) {
            if (showId === id) {
                setShowId(undefined);
            } else {
                setShowId(id);
            }
        }
    }

    return (
        <div className="fixtures-cards-container">
            <table className="fixtures-table-section stats-table-header">
                <tbody>
                {
                    favMatches && favMatches.length >= 0 ?
                        <tr className="league-name-container-for-mobile">
                            {
                                <td className="league-name-and-flag-container">
                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        paddingLeft: '10px'
                                    }}>
                                        {
                                            favMatches && favMatches.length >= 0 ?
                                                <span
                                                    className="table-12px-fav-header">{i18String('Favorites')}</span> :
                                                <a
                                                    href={`/league/${league.title.area.toLowerCase()}-${league.title.league.toLowerCase().replace(/\s+/g, '-')}/${league.title.leagueId}/${league.title.seasonId}`}
                                                    className={'league-name-and-flag'}
                                                >
                                                    <img
                                                        className="country-flag"
                                                        src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.title.areaId}.png`}
                                                        onError={(e) => onImageError(e)}
                                                        alt={`${league.title.area} - ${league.title.league}`}
                                                    />
                                                    <a className="area-info-container">{league.title.area} - {league.title.league}</a>
                                                </a>
                                        }
                                    </div>


                                    <div className='stats-half-time-scores-container'>
                                        {
                                            matches.some((match) => {
                                                return match.st === 'HT' || match.status === 'Played';
                                            }) &&
                                            <div style={{display: 'flex'}}>
                                                <span className='stats-half-time-title'>{i18String('HT')}</span>
                                            </div>
                                        }
                                    </div>

                                </td>
                            }
                            {
                                statsType !== 'avgStats' &&
                                statsHeader['avgStatsHeader'].map((stHeader) => {
                                    return (
                                        <StatsHeader currentStats={currentStats} key={stHeader.text}
                                                     stHeader={stHeader}/>
                                    )
                                })
                            }
                            {
                                statsHeader[currentStats].map((stHeader) => {
                                    return (
                                        <StatsHeader currentStats={currentStats} key={stHeader.text}
                                                     stHeader={stHeader}/>
                                    )
                                })
                            }
                        </tr>
                        :
                        <tr className={'league-name-container'}>
                            {
                                <td
                                    onClick={() => toggleShow(league.title.leagueId)}
                                    className={showId === league.title.leagueId || (showId !== league.title.leagueId && index < 20) ? 'league-name-and-flag-container' : 'league-name-and-flag-container-for-show-more-matches'}
                                >
                                    <div style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        paddingLeft: '10px'
                                    }}>
                                        {
                                            favMatches && favMatches.length >= 0 ?
                                                <span className="table-12px-fav-header">Favorites</span> :
                                                <a
                                                    href={`/league/${league.title.area.toLowerCase()}-${league.title.league.toLowerCase().replace(/\s+/g, '-')}/${league.title.leagueId}/${league.title.seasonId}`}
                                                    className={'league-name-and-flag'}
                                                    target='_blank'
                                                >
                                                    <img
                                                        className="country-flag"
                                                        src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.title.areaId}.png`}
                                                        onError={(e) => onImageError(e)}
                                                        alt={`${league.title.area} - ${league.title.league}`}
                                                    />
                                                    <a className="area-info-container">{league.title.area} - {league.title.league}</a>
                                                </a>
                                        }
                                    </div>

                                    <div>
                                        {
                                            matches.length >= 0 && index >= 20 && !live &&
                                            <div className='show-more-and-less-matches-container'>
                                                {
                                                    showId === league.title.leagueId ?
                                                        <div style={{display: 'flex', placeItems: 'center'}}>
                                                            <span className='show-less-matches-button'
                                                                  onClick={() => toggleShow(league.title.leagueId)}>Hide Matches
                                                            </span>
                                                        </div> :
                                                        <div style={{display: 'flex', placeItems: 'center'}}>
                                                            <span className='show-more-matches-button'
                                                                  onClick={() => toggleShow(league.title.leagueId)}>{matches.length} Matches
                                                            </span>
                                                            <img src={rightIcon} alt=""
                                                                 style={{width: '20px', height: '20px'}}/>
                                                        </div>
                                                }
                                            </div>
                                        }
                                    </div>

                                    {/*SHOWN FOR MORE AND HIDE MATCHES*/}
                                    {
                                        showId === league.title.leagueId &&
                                        matches.some((match) => {
                                            return match.st === 'HT' || match.status === 'Played';
                                        }) &&
                                        <div className='stats-half-time-scores-container'>
                                            <span className='stats-half-time-title'>{i18String('HT')}</span>
                                        </div>
                                    }

                                    {/*NORMAL LISTED*/}
                                    {
                                        showId !== league.title.leagueId && index < 20 &&
                                        matches.some((match) => {
                                            return match.st === 'HT' || match.status === 'Played';
                                        }) &&
                                        <div className='stats-half-time-scores-container'>
                                            <span className='stats-half-time-title'>{i18String('HT')}</span>
                                        </div>
                                    }
                                </td>
                            }
                            {/*SHOWN FOR MORE AND HIDE MATCHES*/}
                            {
                                statsType !== 'avgStats' && showId === league.title.leagueId &&
                                statsHeader['avgStatsHeader'].map((stHeader) => {
                                    return (
                                        <StatsHeader currentStats={currentStats} key={stHeader.text}
                                                     stHeader={stHeader}/>
                                    )
                                })
                            }
                            {
                                showId === league.title.leagueId &&
                                statsHeader[currentStats].map((stHeader) => {
                                    return (
                                        <StatsHeader currentStats={currentStats} key={stHeader.text}
                                                     stHeader={stHeader}/>
                                    )
                                })
                            }

                            {/*NORMAL LISTED*/}
                            {
                                statsType !== 'avgStats' && showId !== league.title.leagueId && index < 20 &&
                                statsHeader['avgStatsHeader'].map((stHeader) => {
                                    return (
                                        <StatsHeader currentStats={currentStats} key={stHeader.text}
                                                     stHeader={stHeader}/>
                                    )
                                })
                            }
                            {
                                showId !== league.title.leagueId && index < 20 &&
                                statsHeader[currentStats].map((stHeader) => {
                                    return (
                                        <StatsHeader currentStats={currentStats} key={stHeader.text}
                                                     stHeader={stHeader}/>
                                    )
                                })
                            }
                        </tr>
                }
                </tbody>
            </table>
            {
                favMatches && favMatches.length >= 0 ?
                    /*I did this because I didn't want it to appear on mobile. I blocked it with class.*/
                    <div className='matches-list-for-mobile'>
                        <Matches index={index} favMatches={favMatches} league={league} matches={matches}
                                 statsType={statsType}
                                 selectedTypeForSelect={selectedTypeForSelect}></Matches>
                    </div>
                    :
                    <div>
                        {
                            !live &&
                            <div>
                                {
                                    index >= 19 && matches.length >= 1 && showId === league.title.leagueId &&
                                    <Matches index={index} favMatches={favMatches} league={league} matches={matches}
                                             statsType={statsType}
                                             selectedTypeForSelect={selectedTypeForSelect}></Matches>
                                }
                                {
                                    index <= 19 &&
                                    <Matches index={index} favMatches={favMatches} league={league} matches={matches}
                                             statsType={statsType}
                                             selectedTypeForSelect={selectedTypeForSelect}></Matches>
                                }
                            </div>
                        }
                        {
                            live &&
                            <Matches index={index} favMatches={favMatches} league={league} matches={matches}
                                     statsType={statsType}
                                     selectedTypeForSelect={selectedTypeForSelect}></Matches>
                        }
                    </div>
            }
        </div>
    );
}

export default LeagueAndMatchesTable;
